import { useState } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { SuspenseTransactionsReportPayload, UrlExport } from './types';

export function useGetUrlExportSuspenseTransactionsReport(
  options?: UseMutationOptions<ApiResponseType<UrlExport>, Error, SuspenseTransactionsReportPayload>
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlExportSuspenseTransactionsReport, isLoading } = useMutation<
    ApiResponseType<UrlExport>,
    Error,
    SuspenseTransactionsReportPayload
  >({
    mutationFn: (payload: SuspenseTransactionsReportPayload) =>
      responseWrapper<ApiResponseType<UrlExport>>(
        apiClient.getUrlExportSuspenseTransactionsReport,
        [payload]
      ),
    ...options,
  });

  return {
    getUrlExportSuspenseTransactionsReport,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
