import { CustomMenu } from 'src/containers/SearchAllDocumentListing/HeaderContainer/CustomMenu';
import { TIME_QUERY_KEY } from './helpers';
import { Stack, Typography } from '@mui/material';
import { DatePicker, View } from '../common';
import { Callback } from 'src/redux/types';
import React from 'react';

type CustomSelectDateRangeProps = {
  handleClickCustomOptions: Callback;
  handleClickOptions: Callback;
  selectValue: any;
  options: any;
  customOptions: any;
  onFromDateChange: Callback;
  onToDateChange: Callback;
  fromDate: Date;
  toDate: Date;
};

const CustomSelectDateRange = ({
  handleClickCustomOptions,
  handleClickOptions,
  selectValue,
  options,
  customOptions,
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
}: CustomSelectDateRangeProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClickOptions = (name, value) => {
    setAnchorEl(null);
    handleClickOptions(name, value);
  };

  return (
    <CustomMenu
      values={selectValue}
      customLabelDisplay={'Custom Date Range'}
      name={TIME_QUERY_KEY.DATE_RANGE_TYPE}
      customOptions={customOptions}
      options={options}
      handleClickCustomOptions={handleClickCustomOptions}
      handleClickOptions={onClickOptions}
      anchorEl={anchorEl}
      handleCloseMenu={() => setAnchorEl(null)}
      handleOpen={(event) => setAnchorEl(event.currentTarget)}
    >
      <Stack flexDirection="row" p="2px" alignItems="center">
        <View
          style={{
            width: '130px',
          }}
        >
          <DatePicker
            name={TIME_QUERY_KEY.DATE_FROM}
            selected={fromDate}
            onChange={onFromDateChange}
          />
        </View>
        <Typography variant="body2" m="0 2px">
          to
        </Typography>
        <View
          style={{
            width: '130px',
          }}
        >
          <DatePicker name={TIME_QUERY_KEY.DATE_TO} selected={toDate} onChange={onToDateChange} />
        </View>
      </Stack>
    </CustomMenu>
  );
};

export default CustomSelectDateRange;
