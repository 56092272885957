export enum NavigateQuery {
  PREVIOUS_PAGE = 'prevPage',
  SEARCH_TEXT = 'searchText',
  WORK_FLOW_STATUS = 'workflowStatus',
}

export enum ErrorEmptyPageMessage {
  DOCUMENT_NOT_FOUND = 'The document does not exist. The document may have been deleted by another user.',
  DOCUMENT_REQUEST_NOT_FOUND = 'The requested document does not exist. The document may have been deleted by another user.',
  NOT_HAVE_PERMISSION = 'You do not have permission to view this document.',
  USER_NOT_HAVE_PERMISSION = "User doesn't have permissions to view this document.",
  FAILED_TO_GET_PO_PAYMENT = 'Get purchase order payment failed for id',
  FAILED_TO_GET_VENDOR_CODE = 'Failed to get vendor for code:',
  FAILED_TO_GET_VENDOR_PAYMENT_TYPE_INFO = 'Failed to get vendor payment type info',
  NOT_FOUND_MULTI_TRAVEL = 'Not found any Multi Travel with id',
  NOT_FOUND_REIMBURSEMENT = 'Not found any reimbursement payment with id',
  PURCHASE_ORDER_BALANCE_MUST_NOT_BE_0 = 'Purchase order balance must not be 0.',
  PURCHASE_ORDER_IS_NOT_IN_FINAL_STATUS = 'Purchase order is not in final status.',
  PURCHASE_ORDER_MUST_NOT_HAVE_A_CHANGE_DOCUMENT_CREATED_ON_IT_ALREADY = 'Purchase order must not have a change document created on it already.',
  CANNOT_CREATE_A_PO_PAYMENT_ON_A_PAYMENT_DOCUMENT = 'Cannot create a PO Payment on a payment document.',
  CANNOT_CREATE_A_CHANGE_DOCUMENT_ON_A_PO_PAYMENT_DOCUMENT = 'Cannot create a change document on a PO payment document.',
  TRAVEL_COMPLETION_WAS_ALREADY_CREATED_ON_TRAVEL_REQUEST = 'Travel Completion was already created on Travel Request.',
  FAILED_TO_CREATE_THE_PURCHASE_ORDER_PAYMENT_THE_USER_DOES_NOT_HAVE_ACCESS_TO_THE_PROJECT = 'Failed to create the Purchase Order Payment. The user does not have access to the project:',
}

export enum ProjectColumnOrder {
  NUMBER = 'number',
  TERMINATED_AND_INACTIVE = 'terminatedAndInactive',
}

export enum TypeFormNotFoundPage {
  VENDOR = 'vendor',
  DOCUMENT = 'document',
}

export interface PlaceholderFileAttachment {
  file: File;
  description: string;
  size: string;
  isArtifact?: boolean;
}
