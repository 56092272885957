import { Stack } from '@mui/material';
import { COLOR_CODE } from 'src/appConfig/constants';
import { SelectOption } from 'src/components/common/Select';
import { FinancialProject } from 'src/queries';
import { DateFormat, getDateWithoutTimeZone } from 'src/utils';
import { isEmpty } from 'src/validations';

export const getFinancialProjectNumberOptions = ({
  projects,
}: {
  projects: FinancialProject[];
}): SelectOption[] => {
  if (isEmpty(projects)) return [];

  return projects.map((project) => {
    const projectPeriod = `(${getDateWithoutTimeZone(
      project.startDate,
      DateFormat
    )} - ${getDateWithoutTimeZone(project.endDate, DateFormat)})`;

    return {
      label: (
        <Stack direction="row" sx={{ display: 'flex', gap: 1 }}>
          <Stack sx={{ minWidth: '50px' }}>{project.number}</Stack>
          <Stack flex={2}>
            <div>
              {isTerminatedProject({ project: project }) && (
                <span style={{ color: COLOR_CODE.DANGER, marginRight: '2px' }}>(terminated)</span>
              )}
              {project.inactive === true && (
                <span style={{ color: COLOR_CODE.DANGER, marginRight: '2px' }}>(inactive)</span>
              )}
              <span>{project.name}</span>
            </div>
          </Stack>
          <Stack flex={1}>PI: {project.piName}</Stack>
          <Stack width={170}>{projectPeriod}</Stack>
        </Stack>
      ),
      value: project,
      // subLabel: `(${getDateWithoutTimeZone(
      //   project.startDate,
      //   DateFormat
      // )} - ${getDateWithoutTimeZone(project.endDate, DateFormat)})`,
    };
  });
};

export const isTerminatedProject = ({ project }: { project: FinancialProject }) =>
  project.ac.trim().length > 0;
