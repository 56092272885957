import { Box, Stack, Typography } from '@mui/material';
import { FC, memo, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SearchChips, { ChipsData } from 'src/components/SearchChips';
import { Select } from 'src/components/common';
import { IRootState } from 'src/redux/store';
import { getEndOfDayDisplay, getOptionLabel, getStartOfDayDisplay, isoFormat } from 'src/utils';
import { PO_LIST_QUERY_KEY, PURCHASING_LIST_WORK_FLOW_STATUS_KEY } from '../enum';
import SearchApproved from './CustomSearch/SearchApproved';
import { paymentMethodOptions, paymentTypeOptions } from './CustomSearch/SearchApproved/helpers';
import SearchPendingReviewApprove from './CustomSearch/SearchPendingReviewApprove';
import {
  CustomFilterPOQueryValue,
  documentTypePendingApproveReviewOptions,
  poStatusOptions,
} from './CustomSearch/SearchPendingReviewApprove/helpers';
import { dateRangeCustomOptions, dateRangeOptions, purchasingListType } from './helpers';
import SearchPOChangePayment from './CustomSearch/SearchPOChangePayment';
import CustomSelectDateRange from 'src/components/CustomSelectDateRange';
import { handleSelectDate, TIME_QUERY_KEY } from 'src/components/CustomSelectDateRange/helpers';
import dayjs from 'dayjs';

export interface SearchChip {
  id: string;
  key: string;
  value: string;
  label: string;
}

const POHeaderTable: FC<Props> = ({ searchValues, currentRole, allowSelectWorkflow = true }) => {
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const searchStatusText = useMemo(
    () => query.get(PO_LIST_QUERY_KEY.WORKFLOW_STATUS) || '',
    [query]
  );

  const createdDate = useMemo(() => {
    const dateFrom = query.get(TIME_QUERY_KEY.DATE_FROM);
    const dateTo = query.get(TIME_QUERY_KEY.DATE_TO);

    return {
      [TIME_QUERY_KEY.DATE_RANGE_TYPE]: query.get(TIME_QUERY_KEY.DATE_RANGE_TYPE) || '',
      [TIME_QUERY_KEY.DATE_FROM]: dateFrom ? dayjs(dateFrom).toDate() : null,
      [TIME_QUERY_KEY.DATE_TO]: dateTo ? dayjs(dateTo).toDate() : null,
    };
  }, [query]);

  useEffect(() => {
    if (!createdDate[TIME_QUERY_KEY.DATE_RANGE_TYPE]) {
      const { dateFrom, dateTo } = handleSelectDate('1');
      query.set(TIME_QUERY_KEY.DATE_RANGE_TYPE, '1');
      query.set(TIME_QUERY_KEY.DATE_FROM, getEndOfDayDisplay(dateFrom, isoFormat));
      query.set(TIME_QUERY_KEY.DATE_TO, getEndOfDayDisplay(dateTo, isoFormat));
      history.push({ search: query.toString() });
    }
  }, [history, query, createdDate]);

  const tableColumnsForChips = useMemo<ChipsData[]>(
    () => [
      {
        name: 'number',
        label: 'PO Number',
        value: searchValues.number,
        type: 'input',
      },
      {
        name: 'projectNumber',
        label: 'Project #',
        value: searchValues.projectNumber,
        type: 'input',
      },
      {
        name: 'vendorName',
        value: searchValues.vendorName,
        type: 'input',
      },
      {
        name: 'faReviewer',
        label: 'FA Staff',
        value: searchValues.faReviewer,
        type: 'input',
      },
      {
        name: 'piName',
        label: 'PI Name',
        value: searchValues.piName,
        type: 'input',
      },
      {
        name: 'modifiedStartDate;modifiedEndDate',
        nameSplitter: ';',
        label: 'Modified Date',
        value: [searchValues.modifiedStartDate, searchValues.modifiedEndDate],
        type: 'dateRange',
      },
      {
        name: 'checkStartDate;checkEndDate',
        nameSplitter: ';',
        label: 'Check Date',
        value: [searchValues.checkStartDate, searchValues.checkEndDate],
        type: 'dateRange',
      },
      {
        name: 'finalApprovedStartDate;finalApprovedEndDate',
        nameSplitter: ';',
        label: 'Approved Date',
        value: [searchValues.finalApprovedStartDate, searchValues.finalApprovedEndDate],
        type: 'dateRange',
      },
      {
        name: 'printedStartDate;printedEndDate',
        nameSplitter: ';',
        label: 'Printed Date',
        value: [searchValues.printedStartDate, searchValues.printedEndDate],
        type: 'dateRange',
      },
      {
        name: 'documentType',
        label: 'Document Type',
        value: searchValues.documentType || [],
        type: 'filter',
        customRenderFn: (value) => getOptionLabel(documentTypePendingApproveReviewOptions, value),
      },
      {
        name: 'status',
        label: 'Status',
        value: searchValues.status || [],
        type: 'filter',
        customRenderFn: (value) => getOptionLabel(poStatusOptions, value),
      },
      {
        name: 'paymentType',
        label: 'Payment Type',
        value: searchValues.paymentType || [],
        type: 'filter',
        customRenderFn: (value) => getOptionLabel(paymentTypeOptions, value),
      },
      {
        name: 'paymentMethod',
        label: 'Payment Method',
        value: searchValues.paymentMethod || [],
        type: 'filter',
        customRenderFn: (value) => getOptionLabel(paymentMethodOptions, value),
      },
      {
        name: 'checkNumber',
        label: 'Check Number',
        value: searchValues.checkNumber,
        type: 'input',
      },
      {
        name: 'paymentRequestNumber',
        label: 'Payment Request #',
        value: searchValues.paymentRequestNumber,
        type: 'input',
      },
    ],
    [searchValues]
  );

  const onSearch = (_: any, value: string) => {
    if (!value) return;
    query.delete('page');
    query.delete('rowsPerPage');
    const dateQueryKeys = [
      TIME_QUERY_KEY.DATE_FROM,
      TIME_QUERY_KEY.DATE_TO,
      TIME_QUERY_KEY.DATE_RANGE_TYPE,
    ];
    for (const searchValue in searchValues) {
      if (
        // eslint-disable-next-line security/detect-object-injection
        searchValues[searchValue] !== undefined &&
        !dateQueryKeys.includes(searchValue as TIME_QUERY_KEY)
      ) {
        query.delete(searchValue);
      }
    }

    const handleSetDefaultSort = () => {
      if (
        [
          PURCHASING_LIST_WORK_FLOW_STATUS_KEY.PO_CHANGE,
          PURCHASING_LIST_WORK_FLOW_STATUS_KEY.PO_PAYMENT,
          PURCHASING_LIST_WORK_FLOW_STATUS_KEY.APPROVED_PO_DOCUMENTS,
        ].includes(value as any)
      ) {
        return 'finalApprovedDate:desc';
      } else {
        return 'updatedAt:desc';
      }
    };

    query.set(PO_LIST_QUERY_KEY.WORKFLOW_STATUS, value);
    query.set(PO_LIST_QUERY_KEY.SORT, handleSetDefaultSort());

    history.push({ search: query.toString() });
  };

  const filteredWorkFlowTypeOptions = useMemo(
    () => purchasingListType.filter((item) => item.roles.some((role) => role === currentRole)),
    [currentRole]
  );

  const changeFormFilterValue = useMemo(() => {
    switch (searchStatusText) {
      case PURCHASING_LIST_WORK_FLOW_STATUS_KEY.PENDING_PO_DOCUMENTS:
      case PURCHASING_LIST_WORK_FLOW_STATUS_KEY.REVIEW_APPROVE_PO_DOCUMENTS:
      case PURCHASING_LIST_WORK_FLOW_STATUS_KEY.ALL_PO_DOCUMENTS:
        return <SearchPendingReviewApprove searchValues={searchValues} />;
      case PURCHASING_LIST_WORK_FLOW_STATUS_KEY.APPROVED_PO_DOCUMENTS:
        return <SearchApproved searchValues={searchValues} />;
      case PURCHASING_LIST_WORK_FLOW_STATUS_KEY.PO_CHANGE:
      case PURCHASING_LIST_WORK_FLOW_STATUS_KEY.PO_PAYMENT:
      case PURCHASING_LIST_WORK_FLOW_STATUS_KEY.OUTSTANDING_PO_DOCUMENTS:
        return (
          <SearchPOChangePayment searchValues={searchValues} searchStatusText={searchStatusText} />
        );
      default:
        return null;
    }
  }, [searchStatusText, searchValues]);

  const handleClickOptions = (_name, value) => {
    const { dateFrom, dateTo } = handleSelectDate(value);
    query.delete('page');
    query.set(TIME_QUERY_KEY.DATE_RANGE_TYPE, value);
    if (dateFrom && dateTo) {
      query.set(TIME_QUERY_KEY.DATE_FROM, dateFrom);
      query.set(TIME_QUERY_KEY.DATE_TO, dateTo);
    } else {
      query.delete(TIME_QUERY_KEY.DATE_FROM);
      query.delete(TIME_QUERY_KEY.DATE_TO);
    }
    history.push({ search: query.toString() });
  };

  const handleClickCustomOptions = (_name, value) => {
    query.set(TIME_QUERY_KEY.DATE_RANGE_TYPE, value);
    history.push({ search: query.toString() });
  };

  const findSelectedOption = (options, value) => {
    const findItem = options.find((item) => item.value === value);
    return {
      [TIME_QUERY_KEY.DATE_RANGE_TYPE]: findItem?.value,
    };
  };

  const handleChangeDateRange = (name, value) => {
    query.delete('page');
    if (name === TIME_QUERY_KEY.DATE_FROM) {
      query.set(name, getStartOfDayDisplay(value, isoFormat));
    } else {
      query.set(name, getEndOfDayDisplay(value, isoFormat));
    }
    history.push({ search: query.toString() });
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Stack direction="row" gap={2}>
        {allowSelectWorkflow && (
          <Stack width="30%">
            <Select
              isClearable={false}
              label="Workflow View"
              hideSearchIcon
              options={filteredWorkFlowTypeOptions}
              value={searchStatusText}
              onChange={onSearch}
              isSearchable={false}
            />
          </Stack>
        )}
        <Stack justifyContent="center" width={200}>
          <Typography variant="body2" mb="0.25rem" lineHeight={1.6}>
            Created Date
          </Typography>
          <CustomSelectDateRange
            customOptions={dateRangeCustomOptions}
            options={dateRangeOptions}
            handleClickOptions={handleClickOptions}
            handleClickCustomOptions={handleClickCustomOptions}
            selectValue={findSelectedOption(
              [...dateRangeOptions, dateRangeCustomOptions],
              createdDate[TIME_QUERY_KEY.DATE_RANGE_TYPE]
            )}
            onFromDateChange={handleChangeDateRange}
            fromDate={createdDate[TIME_QUERY_KEY.DATE_FROM] as Date}
            onToDateChange={handleChangeDateRange}
            toDate={createdDate[TIME_QUERY_KEY.DATE_TO] as Date}
          />
        </Stack>
      </Stack>
      <Box mt={1}>{changeFormFilterValue}</Box>
      <Box>
        <SearchChips data={tableColumnsForChips} />
      </Box>
    </Box>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    searchValues?: Partial<CustomFilterPOQueryValue>;
    allowSelectWorkflow?: boolean;
  };

const mapStateToProps = (state: IRootState) => ({
  currentRole: state.auth.currentRole,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(memo(POHeaderTable));
