import { useState } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, authResponseWrapper } from '../../helpers';
import { KFSBudgetStatusReportPayload, UrlExport } from './types';

export function useGetUrlExportBudgetStatusReport(
  options?: UseMutationOptions<
    ApiResponseType<{ data: UrlExport }>,
    Error,
    KFSBudgetStatusReportPayload
  > & {}
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlExportBudgetStatusReport, isLoading } = useMutation<
    ApiResponseType<{ data: UrlExport }>,
    Error,
    KFSBudgetStatusReportPayload
  >({
    mutationFn: (payload: KFSBudgetStatusReportPayload) => {
      return authResponseWrapper<ApiResponseType<{ data: UrlExport }>>(
        apiClient.getUrlExportBudgetStatusReport,
        [payload]
      );
    },
    ...options,
  });

  return {
    getUrlExportBudgetStatusReport,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
