import { useState } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { KFSBudgetStatusSummaryReportPayload, UrlExport } from './types';

export function useGetUrlExportBudgetStatusSummaryReport(
  options?: UseMutationOptions<
    ApiResponseType<UrlExport>,
    Error,
    KFSBudgetStatusSummaryReportPayload
  >
) {
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [status, setStatus] = useState<'Fetching' | 'Generating' | ''>('');

  const { mutate: getUrlExportBudgetStatusSummaryReport, isLoading } = useMutation<
    ApiResponseType<UrlExport>,
    Error,
    KFSBudgetStatusSummaryReportPayload
  >({
    mutationFn: (payload: KFSBudgetStatusSummaryReportPayload) =>
      responseWrapper<ApiResponseType<UrlExport>>(
        apiClient.getUrlExportKFSBudgetStatusSummaryReport,
        [payload]
      ),
    ...options,
  });

  return {
    getUrlExportBudgetStatusSummaryReport,
    isLoading,
    isOpenSnackbar,
    isDownloading,
    status,
    setIsOpenSnackbar,
    setIsDownloading,
    setStatus,
  };
}
