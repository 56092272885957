import { Grid, Typography } from '@mui/material';
import CustomTable from 'src/components/CustomTable';
import { CellType } from 'src/components/CustomTable/types';
import { CommonFileAttachment } from 'src/containers/shared/FileAttachmentsSection/types';
import { DateFormatDisplayMinute, getDateDisplay } from 'src/utils';

export const FileAttachmentsItems = ({
  fileAttachments,
}: {
  fileAttachments: Partial<CommonFileAttachment>[];
}) => {
  const listItem =
    fileAttachments?.length > 0
      ? fileAttachments.map((fileAttachment) => ({
          columns: [
            {
              content: (
                <Typography variant="body2">{fileAttachment?.isArtifact ? 'X' : ''}</Typography>
              ),
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
            {
              content: <Typography variant="body2">{fileAttachment?.name}</Typography>,
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
            {
              content: (
                <div
                  style={{
                    wordBreak: 'break-word',
                    display: 'block',
                  }}
                >
                  {fileAttachment?.description}
                </div>
              ),
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
            {
              content: (
                <Typography variant="body2">
                  {getDateDisplay(fileAttachment?.uploadDate, DateFormatDisplayMinute)}
                </Typography>
              ),
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
            {
              content: <Typography variant="body2">{fileAttachment?.size}</Typography>,
              colSpan: 2,
              type: CellType.PRINT_CELL,
            },
          ],
        }))
      : [
          {
            columns: [],
          },
        ];
  return (
    <Grid
      spacing={2}
      sx={{
        display: 'none',
      }}
      className={'print__display'}
    >
      <CustomTable.Layout
        bodyList={[
          {
            columns: [
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    Send w/ePmt
                  </Typography>
                ),
                colSpan: 2,
                width: '10%',
                type: CellType.PRINT_CELL,
              },
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    File Attachments Name
                  </Typography>
                ),
                colSpan: 2,
                width: '30%',
                type: CellType.PRINT_CELL,
              },
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    Description
                  </Typography>
                ),
                colSpan: 2,
                width: '30%',
                type: CellType.PRINT_CELL,
              },
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    Upload Date
                  </Typography>
                ),
                colSpan: 2,
                width: '20%',
                type: CellType.PRINT_CELL,
              },
              {
                content: (
                  <Typography textAlign="center" variant="body2" sx={{ fontWeight: 'bold' }}>
                    Size
                  </Typography>
                ),
                colSpan: 2,
                width: '10%',
                type: CellType.PRINT_CELL,
              },
            ],
          },
          ...(listItem as any),
        ]}
      />
    </Grid>
  );
};
