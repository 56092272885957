import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import momentTz from 'moment-timezone';
import { isEmpty } from 'src/validations';

dayjs.extend(weekday);
dayjs.extend(tz);

export const DateFormat = 'MM/DD/YYYY';
export const DateFormatWithHour = 'DD/MM/YYYY HH:mm';
export const DateFormatDisplay = 'MMMM DD, YYYY';
export const DateFormatWithYear = 'YYYY-MM-DD';
export const DateFormatDisplayShort = 'MMM DD, YYYY';
export const DateFormatDisplayMinute = 'MM/DD/YYYY hh:mm A';
export const TimeFormat = 'HH:mm';
export const hourDateFormat = 'h:mm:ss a, MMMM DD, YYYY';
export const dateTimeFormat = 'MM/DD/YYYY HH:MM:ss A';
export const monthFormat = 'MMMM DD, YYYY';
export const isoFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const DatePickerDateTimeFormat = 'MM/dd/yyyy hh:mm aa';
export const isoFormatWithoutTimeZone = 'YYYY-MM-DDTHH:mm:ss.sss';

export type DateType = string | Date | Dayjs;

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getDateDisplay = (value: DateType, format = DateFormat, defaultValue = '') => {
  if (isEmpty(value)) return defaultValue;

  return dayjs(value).format(format);
};

export const getUTCDateDisplay = (value: DateType, format = DateFormat) => {
  if (!value) return '';

  return dayjs.utc(value).format(format);
};

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const formatDateApi = (value: DateType, format = DateFormatWithYear) => {
  if (!value) return null;

  return dayjs(value).format(format);
};

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getEndOfDayDisplay = (value: DateType, format = DateFormat) => {
  if (!value) return '';

  return dayjs(value).endOf('day').format(format);
};

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getStartOfDayDisplay = (value: DateType, format = DateFormat) => {
  if (!value) return '';

  return dayjs(value).startOf('day').format(format);
};

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getTimeDisplay = (value: DateType) => {
  return dayjs(value).format(TimeFormat);
};

/// dayjs has many cases incorrect format with timezone so using moment-timezone for this case
/// Reference issues : https://github.com/iamkun/dayjs/issues/1827
export const localTimeToHawaii = (dateTime, format = DateFormatDisplayMinute) => {
  if (!dateTime) return null;

  const date = momentTz(dateTime).format(DateFormatWithHour);
  return momentTz(date, DateFormatWithHour).utcOffset('-1000').format(format);
};

export const formatDateUtc = (value: DateType) => {
  if (!value || (typeof value === 'string' && isEmpty(value))) {
    return '';
  } else {
    return dayjs(value).utc().format();
  }
};

export const formatDateUtcTimeZone = (value: DateType) => {
  if (!value || (typeof value === 'string' && isEmpty(value))) {
    return '';
  } else {
    return dayjs(value).utc().format('MM/DD/YYYY hh:mm A');
  }
};

export const getDate = (date: DateType, defaultValue = null) => {
  if (!date) return defaultValue;
  return dayjs(date).toDate();
};

export const getUTCDate = (date: DateType, defaultValue = null) => {
  if (!date) return defaultValue;
  return dayjs.utc(date).toDate();
};

export const getFullDayDifference = ({
  startDate,
  endDate,
}: {
  startDate: DateType;
  endDate: DateType;
}) => {
  const oneDay = 60 * 1000 * 60 * 24;

  // must convert them both to dates to begin with in order to use date functions below
  const firstDate = new Date(startDate as Date);
  const secondDate = new Date(endDate as Date);

  // make sure all the fields are filled before proceeding
  if (firstDate && firstDate !== undefined && secondDate && secondDate !== undefined) {
    const [depDate1, depDate2] = [firstDate, secondDate].map(
      (date) => new Date(date.getFullYear() - 1900, date.getMonth(), date.getDate(), 0, 0, 0)
    );

    return Math.floor((depDate2.getTime() - depDate1.getTime()) / oneDay);
  }

  return null;
};

export const getUtcDate = (date: string | Date) => {
  if (!date) return null;

  const utcOffset = dayjs(date).utcOffset();
  return dayjs(date).subtract(utcOffset, 'minutes').toDate();
};

export const getUtcDateWithFormat = (date: string | Date, format = DateFormat) => {
  if (!date) return null;

  const utcOffset = dayjs(date).utcOffset();
  return dayjs(date).subtract(utcOffset, 'minutes').format(format);
};

export const getFixedHstDate = (
  date: string | Date,
  options?: {
    inverse: boolean;
  }
) => {
  if (!date) return null;

  const utcOffset = dayjs(date).utcOffset();
  return !options?.inverse
    ? dayjs(date).subtract(utcOffset, 'minutes').subtract(10, 'hours').toDate()
    : dayjs(date).add(10, 'hours').toDate();
};

export const formatDateAPIWithoutTimeZone = (date: string | Date) => {
  if (!date) return null;

  return dayjs(date).format(isoFormatWithoutTimeZone) + 'Z';
};

export const getDateWithoutTimeZone = (
  date: string | Date,
  formatDate = DateFormatDisplayMinute,
  defaultValue = null
): string => {
  if (!date) return defaultValue;

  const utcDate = dayjs.tz(date).toISOString();
  return dayjs(utcDate).format(formatDate);
};

export const getCurrentTimeZone = () => {
  const formatCurrentDate = dayjs(new Date()).format(isoFormat);
  const currentTimeZone = dayjs(formatCurrentDate).utcOffset() / 60;
  return currentTimeZone;
};
