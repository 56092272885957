import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { CityStateFromZipCode } from './types';

type ZipCodePayload = {
  zipCode: string;
  hasStateAbbreviations: boolean;
};

export function useZipCode(
  options?: UseMutationOptions<CityStateFromZipCode, Error, ZipCodePayload>
) {
  const {
    mutate: checkZipCode,
    isLoading,
    isSuccess,
  } = useMutation<CityStateFromZipCode, Error, ZipCodePayload>({
    mutationFn: (payload) =>
      responseWrapper<CityStateFromZipCode>(apiClient.getCityStateByZipCode, [payload]),
    ...options,
  });

  return {
    checkZipCode,
    isLoading,
    isSuccess,
  };
}
