import { TextareaAutosize as MuiTextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import cn from 'classnames';
import React, { MouseEventHandler, RefObject, useEffect, useRef } from 'react';
import { PrintTextArea } from 'src/containers/PurchaseOrderContainer/PO/helpers/PrintTextArea';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import { Icon } from '..';
import Element from '../Element';
import View from '../View';
import './styles.scss';

const TextareaAutosize: React.FC<CustomTextareaAutosizeProps> = ({
  errorMessage,
  label,
  className,
  containerClassName,
  borderClassName,
  inputRef = null,
  iconName = '',
  resize = 'vertical',
  required,
  disabled,
  isUncontrolledInput = false,
  onIconClick,
  value,
  isRecalculateHeight = false,
  ...props
}) => {
  const id = useRef<string>(`text-area-${getRandomId()}`);
  const uncontrolledInputRef = useRef<HTMLTextAreaElement>(null);
  const defaultTextAreaRef = useRef(null);

  if (isUncontrolledInput && uncontrolledInputRef.current) {
    uncontrolledInputRef.current.value = props.defaultValue?.toString();
  }

  useEffect(() => {
    // setTimeout is used to recalculate the height of the textarea after the value is set
    setTimeout(() => {
      if (isRecalculateHeight && !disabled) {
        const scrollHeight = defaultTextAreaRef.current?.scrollHeight;
        defaultTextAreaRef.current.style.height = `${scrollHeight}px`;
      }
    }, 700);
  });

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
    >
      <View
        className={cn(
          {
            'cpm-container-text-area-auto-size--error': !isEmpty(errorMessage),
          },
          'cpm-container-text-area-auto-size'
        )}
      >
        <View
          className={cn(
            'cpm-container-border-text-area-auto-size',
            {
              'cpm-container-border-text-area-auto-size--disabled': disabled,
            },
            borderClassName
          )}
        >
          <MuiTextareaAutosize
            id={id.current}
            className={cn(
              className,
              'cmp-text-area-auto-size',
              `cmp-text-area-auto-size__resize--${resize}`,
              'hidden-print',
              {
                'cpm-container-text-area-auto-size--error': !isEmpty(errorMessage),
                'cpm-container-text-area-auto-size--disabled': disabled,
              }
            )}
            ref={inputRef ?? (isUncontrolledInput ? uncontrolledInputRef : defaultTextAreaRef)}
            disabled={disabled}
            value={!isUncontrolledInput ? value ?? '' : value}
            {...props}
          />
          {iconName && (
            <Icon name={iconName} className="cmp-text-area__icon" onClick={onIconClick} />
          )}
        </View>

        <PrintTextArea value={props.defaultValue} />
      </View>
    </Element>
  );
};

export type CustomTextareaAutosizeProps = TextareaAutosizeProps & {
  errorMessage?: string;
  containerClassName?: string;
  borderClassName?: string;
  inputRef?: RefObject<HTMLTextAreaElement>;
  iconName?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  resize?: 'horizontal' | 'vertical' | 'bold' | 'none';
  isUncontrolledInput?: boolean;
  onIconClick?: MouseEventHandler<HTMLElement>;
  isRecalculateHeight?: boolean;
};

export default TextareaAutosize;
