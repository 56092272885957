import dayjs from 'dayjs';
import { getEndOfDayDisplay, getStartOfDayDisplay, isoFormat } from 'src/utils';

export enum TIME_QUERY_KEY {
  DATE_RANGE_TYPE = 'dateRangeType',
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
}

export const handleSelectDate = (value) => {
  const dateNow = dayjs().toDate();

  switch (value) {
    case '1':
      const previousOneYear = dayjs().subtract(1, 'year').toDate();
      return {
        [TIME_QUERY_KEY.DATE_TO]: getEndOfDayDisplay(dateNow, isoFormat),
        [TIME_QUERY_KEY.DATE_FROM]: getStartOfDayDisplay(previousOneYear, isoFormat),
      };
    case '5':
      const previousFiveYear = dayjs().subtract(5, 'year').toDate();
      return {
        [TIME_QUERY_KEY.DATE_TO]: getEndOfDayDisplay(dateNow, isoFormat),
        [TIME_QUERY_KEY.DATE_FROM]: getStartOfDayDisplay(previousFiveYear, isoFormat),
      };
    case '10':
      const previousTenYear = dayjs().subtract(10, 'year').toDate();
      return {
        [TIME_QUERY_KEY.DATE_TO]: getEndOfDayDisplay(dateNow, isoFormat),
        [TIME_QUERY_KEY.DATE_FROM]: getStartOfDayDisplay(previousTenYear, isoFormat),
      };
    case 'all':
    case 'custom':
      return {
        [TIME_QUERY_KEY.DATE_TO]: undefined,
        [TIME_QUERY_KEY.DATE_FROM]: undefined,
      };
    default:
      return;
  }
};
