import {
  Box,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ACCEPT_FILE_TYPE,
  COLOR_CODE,
  DOCUMENT__PDF_FILE,
  FILE_OVER_10_MB,
} from 'src/appConfig/constants';
import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel';
import { StyledTableCell, StyledTableRow } from 'src/components/CustomTable';
import FilePreview from 'src/components/FilePreview';
import TypographyLink from 'src/components/TypographyLink';
import { Accordion, Button, Checkbox, FileUpload, TextareaAutosize } from 'src/components/common';
import { PO_PAYMENT_VENDOR_TYPE } from 'src/containers/PurchaseOrderContainer/POPayment/enums';
import { DateFormatDisplayMinute, getErrorMessage, transferSizeFileToBytes } from 'src/utils';
import { CommonFormikProps } from 'src/utils/commonTypes';
import { getDateDisplay } from 'src/utils/dayjsUtils';
import { isEmpty } from 'src/validations';
import { FileAttachmentsItems } from './print';
import { CommonFileAttachment } from './types';
import { isFinalMode } from 'src/queries/PurchaseOrders/helpers';

const FileAttachmentsSection: React.FC<Props> = ({
  prefixFileAttachments,
  formikProps,
  fileAttachments,
  disabled = false,
  allowActionAfterFinalApproveOnly = false,
  loading,
  showUploadProgress = false,
  uploadProgress,
  allowUploadFile = true,
  allowRemoveFile = true,
  defaultExpandedAccordion = true,
  isDisableCheckBox = false,
  fileSelected,
  onFileSelect,
  onDescriptionInputChange,
  onCheckBoxChange,
  onRemoveSelectedFile,
  onGetDecodeUrl,
  onUploadFile,
  onDeleteAttachment,
  onToggleAccordion,
  onDescriptionRowChange,
  isAllowEditDescription = true,
}) => {
  const [isShowExplanation, setIsShowExplanation] = useState<Boolean>(false);
  const { values, touched, errors, getFieldProps, setFieldValue } = formikProps;

  const _getErrorMessage = (fieldName) => {
    return getErrorMessage(fieldName, { touched, errors });
  };

  const isOverrideVendor = useMemo(() => {
    return (
      values?.remittance?.returnRemittanceFlag ||
      [PO_PAYMENT_VENDOR_TYPE.CHECK, PO_PAYMENT_VENDOR_TYPE.TBD].includes(
        values.preferredPaymentMethod
      )
    );
  }, [values?.preferredPaymentMethod, values?.remittance?.returnRemittanceFlag]);

  useEffect(() => {
    if (values?.remittance?.returnRemittanceFlag === true) {
      const updatedFileAttachments = fileAttachments.map((attachment) => ({
        ...attachment,
        isArtifact: false,
      }));

      setFieldValue(`${prefixFileAttachments}`, updatedFileAttachments);
      return;
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.remittance?.returnRemittanceFlag]);

  return (
    <Accordion
      title="File Attachments"
      id="file-attachments"
      onToggle={onToggleAccordion}
      isExpanded={defaultExpandedAccordion}
    >
      <>
        <Box className="hidden-print">
          {allowUploadFile && (
            <>
              <FileUpload
                acceptFileType={ACCEPT_FILE_TYPE}
                onChange={(value: File[]) => onFileSelect(value)}
              />

              <Stack direction={'row'} justifyContent="flex-end" mt={2} mb={1}>
                <Typography variant="body2" fontStyle={'italic'} color={COLOR_CODE.PRIMARY_500}>
                  Please click Upload to save your document.
                </Typography>
              </Stack>
            </>
          )}

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    {
                      label: (
                        <Box>
                          <Typography variant="body2" style={{ color: COLOR_CODE.WHITE }}>
                            Send w/ePmt
                          </Typography>
                          <TypographyLink
                            style={{ color: COLOR_CODE.WHITE }}
                            onClick={() => setIsShowExplanation(!isShowExplanation)}
                          >
                            What's This
                          </TypographyLink>
                        </Box>
                      ),
                      width: '10%',
                    },
                    {
                      label: 'File Attachments Name',
                      width: '25%',
                    },
                    {
                      label: 'Description',
                      width: '25%',
                    },
                    {
                      label: 'Upload Date',
                      width: '20%',
                    },
                    {
                      label: 'Size',
                      width: '10%',
                    },
                    {
                      label: ' ',
                      width: '25%',
                    },
                  ].map((item, index) => (
                    <StyledTableCell key={index} width={item.width}>
                      {item.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isShowExplanation && (
                <Box mb={7} p={2}>
                  <Box sx={{ position: 'absolute' }}>
                    <Typography variant="body2">
                      If vendor preferred payment type is ACH or Card, you can send a single file
                      attachment with the ePayment.
                    </Typography>
                    <Typography variant="body2">The file MUST be a PDF and under 10MB.</Typography>
                    <Typography variant="body2">
                      Select the file you want to send by checking the "Send w/ePmt" checkbox.
                    </Typography>
                  </Box>
                </Box>
              )}

              <TableBody
                sx={{
                  border: COLOR_CODE.DEFAULT_BORDER,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                }}
              >
                {/* Upload file */}
                {allowUploadFile && fileSelected && (
                  <StyledTableRow>
                    <StyledTableCell>{}</StyledTableCell>
                    <StyledTableCell>
                      <FilePreview.LocalFilePreview file={fileSelected.file} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextareaAutosize
                        onChange={onDescriptionInputChange}
                        maxLength={5000}
                        value={fileSelected.description}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{dayjs().format(DateFormatDisplayMinute)}</StyledTableCell>
                    <StyledTableCell>{fileSelected.size}</StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="row" justifyContent={'flex-end'}>
                        {/* {(!!uploadProgress || isLoadingGetPresignedUrl) && ( */}
                        {showUploadProgress && (
                          <CircularProgressWithLabel
                            variant="determinate"
                            value={uploadProgress}
                            size={34}
                          />
                        )}
                        <Button className="mx-8" disabled={loading} onClick={onUploadFile}>
                          Upload
                        </Button>
                        <Button variant="outline" onClick={onRemoveSelectedFile}>
                          Remove
                        </Button>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {/* View files */}
                {isEmpty(fileAttachments) ? (
                  <StyledTableRow>
                    <StyledTableCell width={'100%'} colSpan={5}>
                      <Box minHeight={'40px'}>&nbsp;</Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  fileAttachments.map((attachment, index) => {
                    const allowRemoveUploadedFile = allowActionAfterFinalApproveOnly
                      ? allowRemoveFile && attachment?.afterFinalApprove
                      : allowRemoveFile;
                    return (
                      <StyledTableRow key={attachment.id}>
                        <StyledTableCell>
                          <Checkbox.Item
                            style={{ justifyContent: 'center' }}
                            {...getFieldProps(`${prefixFileAttachments}.${index}.isArtifact`)}
                            errorMessage={_getErrorMessage(
                              `${prefixFileAttachments}.${index}.isArtifact`
                            )}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              onCheckBoxChange(e, index)
                            }
                            disabled={
                              isFinalMode(values?.status) ||
                              isDisableCheckBox ||
                              disabled ||
                              isOverrideVendor ||
                              transferSizeFileToBytes(attachment.size) >
                                FILE_OVER_10_MB.SIZE_NUMBER ||
                              !attachment?.name?.includes(DOCUMENT__PDF_FILE.FILE_NAME) ||
                              !allowRemoveUploadedFile
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <FilePreview.DecodePreview
                            fileUrl={attachment.url}
                            getDecodeUrl={() =>
                              onGetDecodeUrl({
                                attachmentId: attachment.id,
                                fileUrl: attachment.url,
                              })
                            }
                            fileName={attachment.name}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            wordBreak: 'break-word',
                          }}
                        >
                          {isAllowEditDescription && allowRemoveUploadedFile ? (
                            <TextareaAutosize
                              onChange={(event) => {
                                onDescriptionRowChange(attachment.id, event);
                              }}
                              value={attachment.description}
                              maxLength={5000}
                            />
                          ) : (
                            attachment.description
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {getDateDisplay(attachment.uploadDate, DateFormatDisplayMinute)}
                        </StyledTableCell>
                        <StyledTableCell>{attachment.size}</StyledTableCell>
                        <StyledTableCell>
                          <Stack direction="row" justifyContent={'flex-end'}>
                            {allowRemoveUploadedFile && (
                              <Button
                                variant="outline"
                                onClick={() => {
                                  onDeleteAttachment(attachment);
                                }}
                                disabled={disabled || loading}
                              >
                                Remove
                              </Button>
                            )}
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <FileAttachmentsItems fileAttachments={fileAttachments} />
      </>
    </Accordion>
  );
};
type Props = {
  prefixFileAttachments?: string;
  formikProps?: CommonFormikProps<any>;
  fileAttachments: Partial<CommonFileAttachment>[];
  disabled: boolean;
  allowActionAfterFinalApproveOnly?: boolean;
  loading: boolean;
  uploadProgress: number;
  showUploadProgress: boolean;
  allowUploadFile: boolean;
  allowRemoveFile: boolean;
  defaultExpandedAccordion: boolean;
  fileSelected: Partial<CommonFileAttachment>;
  isDisableCheckBox?: boolean;
  onFileSelect: (files: File[]) => void;
  onDescriptionInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onCheckBoxChange?: (event: React.ChangeEvent<HTMLInputElement>, index) => void;
  onRemoveSelectedFile: () => void;
  onUploadFile: () => void;
  onDeleteAttachment: (attachment: Partial<CommonFileAttachment>) => void;
  onGetDecodeUrl: ({
    attachmentId,
    fileUrl,
  }: {
    attachmentId: string;
    fileUrl: string;
  }) => Promise<string>;
  onToggleAccordion?: (value: boolean) => void;
  onDescriptionRowChange: (id: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isAllowEditDescription?: boolean;
};

export default React.memo(FileAttachmentsSection);
